const baseUrl = process.env.REACT_APP_NODE_SERVER_API_URL;
const templateMatchUrl = process.env.REACT_APP_MATCH_TEMPLATE_URL;
const mlExtractUrl = process.env.REACT_APP_ML_EXTRACT_OVERLAYS_URL;
const apiVersion = 1;
const session = () => JSON.parse(localStorage.getItem('session') || '{}');

const LegacyEndpoints = {
  USER_INFO: 'getuserinfo',
};

const EndPoints = {
  RECORDS: {
    UPDATE: 'records/update',
  },
};

const commonOptions = () => {
  const data = session();
  return {
    mode: 'cors',
    headers: {
      'Content-type': 'application/json; charset=utf-8',
      Authorization: `bearer legacy.${data.userid}.${data.auth}.${data.cpid}`,
    },
  };
};

export class API {
  static async requestLegacy(uri, data) {
    const response = await fetch(`${baseUrl}/${uri}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        ...session(),
        ...data,
        compress: 'false',
      }),
    });
    if(response.status >= 400) {
      throw Error(`Fetch failed for ${uri}`)
    }
    const body = await response.json();
    if(body.errorCode && body.errorCode >= 400) {
      throw Error(body.errorMessage)
    }
    return {
      response,
      body: await body,
    };
  }

  static async get(uri, version = apiVersion) {
    return fetch(`${baseUrl}/api/v${version}/${uri}`, {
      method: 'GET',
      ...commonOptions(),
    });
  }

  static async post(uri, data, version = apiVersion) {
    return fetch(`${baseUrl}/api/v${version}/${uri}`, {
      method: 'POST',
      body: JSON.stringify(data),
      ...commonOptions(),
    });
  }

  static async delete(uri, data, version = apiVersion) {
    return fetch(`${baseUrl}/api/v${version}/${uri}`, {
      method: 'DELETE',
      body: JSON.stringify(data),
      ...commonOptions(),
    });
  }

  static async login(email, password) {
    const response = await API.post('users/login/web', { user: email, pass: password  });
    if (response.status !== 200) {
      const error = await response.text();
      return {
        errorCode: response.status,
        errorMessage: error,
      };
    }
    const result = await response.json();
    return result;
  }

  static async getUserInfo() {
    return API.requestLegacy(LegacyEndpoints.USER_INFO);
  }

  static getDrawingUrl(cpid, drawingName) {
    return `${baseUrl}/getdrawingimage?cpid=${encodeURIComponent(cpid.toString())}&drawing=${encodeURIComponent(drawingName)}`;
  }

  static async getTemplateMatchData(selectShapeData, drawingId, cpid) {
    const response = await fetch(`${templateMatchUrl}/match_template/?coordinates=${encodeURI(JSON.stringify(selectShapeData))}&drawing_id=${drawingId}&cpid=${cpid}`, {
      method: 'GET',
      ...commonOptions(),
    });
    return await response.json()
  }

  static async getMLExtract(cpid, drawingName, model, options) {
    const drawingUrl = this.getDrawingUrl(cpid, drawingName);
    return fetch(drawingUrl)
      .then(res => res.arrayBuffer())
      .then(buffer => {
        var binary = '';
        var bytes = [].slice.call(new Uint8Array(buffer));

        bytes.forEach((b) => binary += String.fromCharCode(b));

        const imageStr = window.btoa(binary)
          .replace(/\//g, '_')
          .replace(/\+/g, '-')
        ;
        return fetch(`${mlExtractUrl}/extract-overlays`,
          {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json"
            },
            body: JSON.stringify({
              instances: [imageStr],
              options,
              params: {
                model
              }
            })
          })
      })
      .then(res => res.json())
      .then(res => {
        return res
      })
    ;
  }

  static async getMlModels() {
    return fetch(`${mlExtractUrl}/extract-overlays`)
      .then(res => res.json())
  }

  static async getMaterialTypes(cpid) {
    const { body } = await API.requestLegacy('getmaterialtypes', { cpid });
    return Object.keys(body).map((key) => {
      const mt = body[key];
      const { name, is_container } = mt;
      return {
        id: key.toString(),
        isContainer: is_container,
        cpid,
        name,
      };
    });
  }

  static async getDrawings(cpid, pid) {
    const response = await API.get(`drawings?projectId=${pid}`);
    const body = await response.json();
    const sortedBody = body.sort((a,b)=>{
      const textA = a.displayName.toUpperCase();
      const textB = b.displayName.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    return sortedBody.map(({ displayName, id, projectId }) => ({
      id,
      displayName,
      projectId,
      cpid,
    }));
  }

  static async getMaterialsWithoutOverlays(pid, mtid) {
    const response = await API.post('materials/materialsWithoutOverlays', { projectId: pid, mtid });
    if (response.status !== 200) {
      console.error(await response.text());
      return [];
    }
    return response.json();
  }

  static async saveShapes(data) {
    const { body } = await API.requestLegacy('addmaterials', data);
    return body;
  }

  static async saveOverlays(overlays, idMap) {
    const data = overlays.reduce((acc, overlay) => {
      const { material_id } = overlay;
      acc.push({
        ...overlay,
        material_id: idMap ? idMap[material_id] : material_id,
      });
      return acc;
    }, []);
    const response = await API.post('materials/addoverlays', data);
    if (response.status !== 200) {
      throw Error(await response.text());
    }
    return response.json();
  }

  static async updateOverlays(overlays, idMap) {
    const data = overlays.reduce((acc, overlay) => {
      const { material_id } = overlay;
      acc.push({
        ...overlay,
        material_id: idMap ? idMap[material_id] : material_id,
      });
      return acc;
    }, []);
    const response = await API.post('materials/updateoverlays', data);
    if (response.status !== 200) {
      throw Error(await response.text());
    }
  }

  static async deleteOverlaysByIds(data) {
    const response = await API.post('materials/deleteoverlays', data);
    if (response.status !== 200) {
      throw Error(await response.text());
    }
  }

  static async updateShapes(data) {
    const response = await API.post(EndPoints.RECORDS.UPDATE, data);
    if (response.status !== 200) {
      throw Error(await response.text())
    }
  }

  static async deleteMaterialById(materialIdArray) {
    return API.requestLegacy('deletematerialsbyid', {
      pridArray: materialIdArray.join(','),
    });
  }

  static async getMaterialsByType(cpid, mtid) {
    const response = await API.get(`materials/allProjectMaterialsByType?cpid=${cpid}&mtid=${mtid}`);
    if (response.status === 200) {
      return response.json();
    }
    console.error(await response.text());
    return {};
  }

  static async getDraftByProjectId(projectId) {
    const response = await API.get(`drafts/${projectId}`)
    if (response.status !== 200) {
      throw Error(await response.text());
    }
    return response.json();
  }

  static async createDraftByProjectId(data) {
    const response = await API.post(`drafts/`, data)
    if (response.status !== 200) {
      throw Error(await response.text());
    }
    return response.json();
  }

  static async cleanUpDraftByDraftProjectId(data) {
    const response = await API.delete(`drafts/`, data)
    if (response.status !== 200) {
      throw Error(await response.text());
    }
    return response.text();
  }

  static async publishDraft(data) {
    const response = await API.post(`drafts/publish`, data)
    if (response.status !== 200) {
      throw Error(await response.text());
    }
    return response.text();
  }

}

export function isApiError(body) {
  return body.errorCode && body.errorMessage;
}

