import { ButtonGroup, createStyles, WithStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import { connect } from 'react-redux'
import { ActionCreators } from 'redux-undo'
import action from 'store/actions'
import {
  getSelectedMaterialType,
  getSelectedProject,
  getSnappingEnabled,
  isToolBusy,
} from 'store/selectors'
import Swal from 'sweetalert2'
import withSelectedValues, { DataloaderInfo } from 'utils/withSelectedValues'

const styles = createStyles({
  root: {
    paddingTop: '10px',
    paddingBottom: '10px',
    flexWrap: 'nowrap',
  },
  button: {
    width: '100%',
  },
  separatedButton: {
    width: '100%',
    marginTop: '5px',
  },
  undoableButtonGroup: {
    justifyContent: 'center',
    width: '100%',
    marginTop: '10px',
  },
  save: {
    marginTop: '10px',
    outline: 'none',
    width: '100%',
    textTransform: 'none',
    border: 'none',
    padding: '10px 10px',
    display: 'block',
    fontWeight: 'normal',
    textAlign: 'center',
    fontSize: '12px',
    boxShadow: '0px 2px 5px 1px #d9d9d9',
    color: '#595959',
    borderRadius: '5px',
  },
})

type Direct = 'top' | 'bottom' | 'left' | 'right' | 'width' | 'height'

interface DispatchProps {
  dispatchAlign: (direct: Direct) => void
  dispatchSaveAction: (materialType: number, cpid: number) => void
  dispatchSnappingEnabled: (enabled: boolean) => void
  dispatchUndo: () => void
  dispatchRedo: () => void
  dispatchClearHistory: () => void
}

interface StateProps {
  isToolBusy: boolean
  snappingEnabled: boolean
  project: ReduxStore.Projects.Data.Project | undefined
  materialType: ReduxStore.MaterialTypes.Data.MaterialType | undefined
  draftState: ReduxStore.Draft.State
  undoLength: number
  redoLength: number
}

type StylesProps = WithStyles<typeof styles>

type Props = DispatchProps & StateProps & StylesProps & DataloaderInfo

const Buttons: React.FunctionComponent<Props> = props => {
  const align = (direct: Direct) => () => {
    const { dispatchAlign } = props
    dispatchAlign(direct)

    // @ts-ignore
    window.analytics.track('Align Tool Used', {
      direction: direct,
      platform: 'dataloader',
      project: {
        id: props.projectId,
        name: props.project ? props.project.projectName : '',
      },
      materialType: {
        id: props.selectedMaterialType,
        name: props.materialType ? props.materialType.name : '',
      },
      drawing: props.selectedDrawing,
    })
  }

  const saveShapes = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { selectedMaterialType, dispatchSaveAction, selectedProject } = props
    props.dispatchClearHistory()

    if (selectedMaterialType && selectedProject) {
      try {
        await dispatchSaveAction(selectedMaterialType, selectedProject)
      } catch (e) {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: `Error occured when saving! ${e.toString()}`,
        })
      }
    }

    // @ts-ignore
    window.analytics.track('Dataloader Saved', {
      platform: 'dataloader',
      project: {
        id: props.projectId,
        name: props.project ? props.project.projectName : '',
      },
      materialType: {
        id: props.selectedMaterialType,
        name: props.materialType ? props.materialType.name : '',
      },
      drawing: props.selectedDrawing,
    })
  }

  const undo = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    props.dispatchUndo()
  }

  const {
    classes: { root, button, save, separatedButton, undoableButtonGroup },
    isToolBusy,
    snappingEnabled,
    dispatchSnappingEnabled,
    draftState,
  } = props
  const { draftData, isPending } = draftState
  const haveDraft = draftData && draftData.draftProject

  return (
    <React.Fragment>
      <div className={root}>
        <button className={button} type="button" onClick={align('top')}>
          Align Top
        </button>
        <button className={button} type="button" onClick={align('bottom')}>
          Align Bottom
        </button>
        <button className={button} type="button" onClick={align('left')}>
          Align Left
        </button>
        <button className={button} type="button" onClick={align('right')}>
          Align Right
        </button>
        <button className={button} type="button" onClick={align('width')}>
          Same Width
        </button>
        <button className={button} type="button" onClick={align('height')}>
          Same Height
        </button>
        <button
          className={separatedButton}
          type="button"
          onClick={() => dispatchSnappingEnabled(!snappingEnabled)}
        >
          {snappingEnabled ? 'Disable' : 'Enable'} Snapping
        </button>
        <ButtonGroup classes={{ root: undoableButtonGroup }}>
          <Button onClick={props.dispatchUndo} disabled={props.undoLength === 0}>
            undo
          </Button>
          <Button onClick={props.dispatchRedo} disabled={props.redoLength === 0}>
            Redo
          </Button>
        </ButtonGroup>
        <Button
          classes={{ root: save }}
          type="button"
          onClick={saveShapes}
          disabled={isPending || isToolBusy}
        >
          Save
        </Button>
      </div>
    </React.Fragment>
  )
}

const mapDispatchToProps = {
  dispatchAlign: action.alignShapes,
  dispatchSaveAction: action.saveAction,
  dispatchSnappingEnabled: action.setSnappingEnabled,
  dispatchUndo: ActionCreators.undo,
  dispatchRedo: ActionCreators.redo,
  dispatchClearHistory: ActionCreators.clearHistory,
}

const mapStateToProps = (state: ReduxStore.State, ownProps: DataloaderInfo) => ({
  isToolBusy: isToolBusy(state),
  snappingEnabled: getSnappingEnabled(state),
  project: getSelectedProject(state, ownProps.projectId),
  materialType: getSelectedMaterialType(state, ownProps.selectedMaterialType),
  draftState: state.draft,
  undoLength: state.materials.past.length,
  redoLength: state.materials.future.length,
})

export default withStyles(styles)(
  withSelectedValues(
    // @ts-ignore
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Buttons)
  )
)
