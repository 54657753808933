import { ToolName as OutlinerToolName } from 'dataloader/tools/OutlinerTool'

const initState: ReduxStore.Tool.State = {
  activeTool: '',
  snappingEnabled: true,
  options: {
    [OutlinerToolName]: {
      colourThreshold: 27,
      blurRadius: 5,
      contourTolerance: 0,
      minVertices: 3,
      useBoundingBox: false,
    },
  },
}

function tool(state: ReduxStore.Tool.State = initState, action: ReduxStore.Tool.Action) {
  switch (action.type) {
    case 'SELECT_TOOL':
      return {
        ...state,
        activeTool: action.payload,
      }

    case 'SET_SNAPPING_ENABLED':
      return {
        ...state,
        snappingEnabled: action.payload,
      }

    case 'SET_TOOL_OPTIONS':
      return {
        ...state,
        options: {
          ...state.options,
          [action.toolName]: action.options,
        },
      }

    default:
      return state
  }
}

export default tool
