import {
  drawGuides,
  getAnchorPoints,
  getGuides,
  getLineGuideStops,
} from 'dataloader/utils/guideLines'
import Konva from 'konva'
import React, { useState } from 'react'
import { Group, Rect } from 'react-konva'
import { connect } from 'react-redux'
import action from 'store/actions'
import { incrementMaterialPresets } from 'store/materialPresets/actions'
import {
  getAutoIncrementPresetsEnabled,
  getDescriptions,
  getDescriptionsData,
  getDrawings,
  getMaterialTypePresets,
  getSnappingEnabled,
} from 'store/selectors'
import { opposingPointsToDimensions } from 'utils/mathUtils'
import randomId from 'utils/randomId'
import { dimensionsToPoints } from 'utils/shape'
import { throttleRaf } from 'utils/throttleRaf'
import withSelectedValues, { DataloaderInfo } from 'utils/withSelectedValues'

interface OwnProps {
  width: number
  height: number
}

interface StateProps {
  descriptions: string[]
  drawings: ReduxStore.Drawings.Data.Drawing[]
  descriptionsData: ReduxStore.Materials.Data.DescriptionData[]
  presets: ReduxStore.MaterialPresets.MaterialPreset[]
  snappingEnabled: boolean
  autoIncrementEnabled: boolean
}

interface DispatchProps {
  addRectangle: (m: ReduxStore.Materials.Data.IMaterial) => ReduxStore.Materials.Action
  incrementPresets: (materialTypeId: number) => ReduxStore.MaterialPresets.IncrementMaterialPresets
}

type Props = OwnProps & StateProps & DispatchProps & DataloaderInfo

const RectangleTool: React.FunctionComponent<Props> = props => {
  const [anchorPoint, setAnchorPoint] = useState<number[] | undefined>(undefined)
  const [cursorPoint, setCursorPoint] = useState<number[] | undefined>(undefined)
  const [transformForMove, setTransform] = useState<Konva.Transform | undefined>(undefined)
  const {
    descriptions,
    drawings,
    addRectangle,
    incrementPresets,
    selectedDrawing,
    selectedProject,
    selectedMaterialType,
    width,
    height,
    autoIncrementEnabled,
  } = props

  // @ts-ignore
  const getObjectSnappingEdges = evt => {
    const pos = eventToCoordinates(evt)
    if (pos.x && pos.y) {
      return {
        vertical: [
          {
            guide: Math.round(pos.x),
            offset: 0,
            snap: 'start',
          },
          {
            guide: Math.round(pos.x),
            offset: 0,
            snap: 'end',
          },
        ],
        horizontal: [
          {
            guide: Math.round(pos.y),
            offset: 0,
            snap: 'start',
          },
          {
            guide: Math.round(pos.y),
            offset: 0,
            snap: 'end',
          },
        ],
      }
    }
    return null
  }

  const handleMouseMove = throttleRaf((evt: Konva.KonvaEventObject<MouseEvent>) => {
    // @ts-ignore
    if (
      evt.target.findAncestor('Stage') !== undefined &&
      // @ts-ignore
      evt.target.findAncestor('Stage').getPointerPosition() !== undefined
    ) {
      evt.target
        .findAncestor('Layer')
        // @ts-ignore
        .find('.guid-line')
        .destroy()

      const lineGuideStops = getLineGuideStops(evt.target)
      const itemBounds = getObjectSnappingEdges(evt)
      const guides = getGuides(lineGuideStops, itemBounds)
      if (props.snappingEnabled) {
        drawGuides(guides, evt.target)
      }

      if (transformForMove && anchorPoint) {
        const pos = { ...eventToCoordinatesForMove(evt) }
        if (props.snappingEnabled) {
          const { presetX, presetY } = getAnchorPoints(guides)
          pos.x = presetX || pos.x
          pos.y = presetY || pos.y
        }
        setAnchorPoint([pos.x, pos.y])
      }
    }
  })

  const handleMouseDown = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    evt.evt.stopPropagation()
    // Get snapping guide lines
    // @ts-ignore
    const lineGuideStops = getLineGuideStops(evt.target)
    // @ts-ignore
    const itemBounds = getObjectSnappingEdges(evt)
    const guides = getGuides(lineGuideStops, itemBounds)
    const { presetX, presetY } = getAnchorPoints(guides)

    const pos = { ...eventToCoordinates(evt) }
    if (props.snappingEnabled) {
      pos.x = presetX || pos.x
      pos.y = presetY || pos.y
    }

    setTransform(evt.target.getAbsoluteTransform().copy())
    // need to set cursorPoint: if user double click as same pos and not move cursor
    // the cursorPoint wiil be null as default and errors happens in opposingPointsToDimensions
    setCursorPoint([pos.x, pos.y])
    setAnchorPoint([pos.x, pos.y])
  }

  const handleMouseUp = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    evt.target
      .findAncestor('Layer')
      // @ts-ignore
      .find('.guid-line')
      // @ts-ignore
      .destroy()
    evt.evt.stopPropagation()
    const dimensions = opposingPointsToDimensions(anchorPoint, cursorPoint)
    // const rectPoints = convertToRectPoints(dimensions);
    if (autoIncrementEnabled) {
      incrementPresets(selectedMaterialType as number)
    }
    createNewMaterial(randomId(), dimensions)
    // Clear the points
    setAnchorPoint(undefined)
    setCursorPoint(undefined)
  }

  const eventToCoordinates = (
    evt: Konva.KonvaEventObject<MouseEvent>
  ): { x: number; y: number } => {
    const transform = evt.target.getAbsoluteTransform().copy()
    transform.invert()
    // @ts-ignore
    return transform.point(evt.target.findAncestor('Stage').getPointerPosition())
  }

  const eventToCoordinatesForMove = (
    evt: Konva.KonvaEventObject<MouseEvent>
  ): { x: number; y: number } => {
    // @ts-ignore
    const transform = transformForMove.copy()
    transform.invert()
    // @ts-ignore
    return transform.point(evt.target.findAncestor('Stage').getPointerPosition())
  }

  const createNewMaterial = (
    overlay_id: string,
    dimensions: ReduxStore.Materials.Data.IDimensions
  ) => {
    const points = dimensionsToPoints(dimensions)
    addRectangle({
      overlay_id,
      material_id: overlay_id,
      drawing_id: drawings.filter(drawing => drawing.displayName === selectedDrawing)[0].id,
      display_name: selectedDrawing as string,
      shape: 'rectangle',
      shape_data: JSON.stringify({ points }),
      company_project_id: selectedProject as number,
      material_type_id: selectedMaterialType as number,
      value: descriptions.reduce((acc: { [key: string]: string }, name) => {
        if (name === 'Drawing Name' && selectedDrawing) {
          acc[name] = selectedDrawing
        } else {
          // Check if there is a preset set for this.
          const preset = props.presets.find(preset => preset.description === name)
          if (preset) {
            acc[name] = preset.value
          } else {
            const descriptionData = props.descriptionsData.find(
              descData => descData.description === name
            )
            if (
              descriptionData &&
              (descriptionData.type === 'list' || descriptionData.type === 'button-list') &&
              descriptionData.choices.length > 0
            ) {
              acc[name] = descriptionData.choices[0]
            } else {
              acc[name] = ''
            }
          }
        }
        return acc
      }, {}),
    })
  }

  const dimensions =
    anchorPoint && cursorPoint ? opposingPointsToDimensions(anchorPoint, cursorPoint) : null

  return (
    <Group onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}>
      <Rect x={0} y={0} width={width} height={height} fill="rgba(0, 0, 0, 0)" />
      {dimensions ? (
        <Rect
          fill="rgba(255, 137, 50, 0.4)"
          stroke="rgba(255, 137, 0, 1)"
          strokeWidth={5}
          {...dimensions}
        />
      ) : null}
    </Group>
  )
}

const mapStateToProps = (
  state: ReduxStore.State,
  { selectedMaterialType }: { selectedMaterialType: string }
) => ({
  descriptions: getDescriptions(state),
  descriptionsData: getDescriptionsData(state),
  drawings: getDrawings(state),
  snappingEnabled: getSnappingEnabled(state),
  presets: getMaterialTypePresets(state, selectedMaterialType),
  autoIncrementEnabled: getAutoIncrementPresetsEnabled(state),
})

const mapDispatchToProps = {
  addRectangle: action.addMaterial,
  incrementPresets: incrementMaterialPresets,
}

export default withSelectedValues(
  // @ts-ignore
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RectangleTool)
)
