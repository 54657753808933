import { HotTable } from '@handsontable/react'
import { createStyles, WithStyles } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { range } from 'lodash'
import React, { Dispatch, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import action from 'store/actions'
import { getMaterialsWithoutOverlays } from 'store/selectors'
import classNames from 'utils/classNames'

const styles = createStyles({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
})

interface StateProps {
  materialsWithoutOverlays: ReduxStore.Materials.Data.IMaterialWithoutOverlays[]
}

interface DispatchProps {
  dispatchDeleteSelectedRecordsByIds: (selection: Array<string | number>) => void
}

interface OwnProps {
  className?: string
}

type Props = StateProps & WithStyles<typeof styles> & OwnProps & DispatchProps

const MaterialTableWithoutOverlays: React.FC<Props> = props => {
  const tableRef = React.createRef<HotTable>()
  const [selectedMaterialIds, setSelectedMaterialIds] = useState<number[]>([])
  const { className, classes, materialsWithoutOverlays, dispatchDeleteSelectedRecordsByIds } = props

  function getRecordsData() {
    return materialsWithoutOverlays.map(m => {
      const { material_id, value } = m
      value.id = material_id.toString()
      return value
    })
  }

  const [data, setData] = useState(getRecordsData())

  useEffect(() => {
    setData(getRecordsData())
  }, [materialsWithoutOverlays])

  const descriptions = new Set(['id'])
  materialsWithoutOverlays.forEach(m => {
    const { value } = m
    Object.keys(value).forEach(des => {
      descriptions.add(des)
    })
  })

  const columns = Array.from(descriptions).map(des => {
    return { data: des }
  })

  const deleteRowsFromHOT = (key: any, selection: any) => {
    const newData = data.filter(record => selectedMaterialIds.indexOf(parseInt(record.id, 10)) < 0)
    setData(newData)
    dispatchDeleteSelectedRecordsByIds(selectedMaterialIds)
  }

  const handleSelect = () => {
    if (tableRef.current) {
      const selectedCells = tableRef.current.hotInstance.getSelected()
      if (selectedCells) {
        const selectedRows = selectedCells.reduce((acc: number[], item) => {
          const startRow = item[0]
          const endRow = item[2]
          if (startRow === endRow) {
            acc.push(startRow)
          } else {
            acc = acc.concat(range(startRow, endRow + 1))
          }
          return acc
        }, [])
        setSelectedMaterialIds(
          selectedRows.map(rowIndex => {
            const material = materialsWithoutOverlays[rowIndex]
            return material.material_id
          })
        )
      }
    }
  }

  return (
    <div className={classNames(className, classes.root)}>
      <HotTable
        licenseKey="bc642-c159b-ce583-14136-ac809"
        ref={tableRef}
        data={data}
        columns={columns}
        colHeaders={Array.from(descriptions)}
        afterSelectionEnd={handleSelect}
        outsideClickDeselects={false}
        selectionMode="multiple"
        rowHeaders={true}
        manualColumnResize={true}
        manualRowResize={true}
        autoColumnSize={true}
        autoRowSize={true}
        // @ts-ignore
        contextMenu={{
          items: {
            remove_row: {
              callback: deleteRowsFromHOT,
            },
          },
        }}
      />
    </div>
  )
}

const mapStateToProps = (state: ReduxStore.State) => ({
  materialsWithoutOverlays: getMaterialsWithoutOverlays(state),
})

const mapDispatchToProps = {
  dispatchDeleteSelectedRecordsByIds: action.deleteSelectedRecordsByIds,
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
    // @ts-ignore
  )(MaterialTableWithoutOverlays)
)
