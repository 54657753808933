import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { getSession } from 'store/selectors'

interface StateProps {
  session: ReduxStore.Auth.Data.Session
}

interface Props extends StateProps {
  component: React.ComponentType<any>
}

const AuthenticatedRoute: React.FC<Props> = ({ component: Component, session, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        session ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  )
}

const mapStateToProps = (state: ReduxStore.State) => ({
  session: getSession(state),
})

// @ts-ignore
export default connect(mapStateToProps)(AuthenticatedRoute)
