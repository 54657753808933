import { createStyles, WithStyles } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { API } from 'api'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import classNames from 'utils/classNames'
import withSelectedValues, { DataloaderInfo } from 'utils/withSelectedValues'
import CenteredSpinner from '../../../shared/CenteredSpinner'
import { getUpdateFloorplanAndTableCanvas } from '../../../store/selectors'
import FloorplanImage from './FloorplanImage'
import Zoomable from './Zoomable'

const styles = createStyles({
  floorplanWrapper: {
    flex: 1,
  },
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
})

interface StateProps {
  updateFloorplanAndTableCanvas: number
}

interface OwnProps {
  className?: string
}

type Props = WithStyles<typeof styles> & DataloaderInfo & OwnProps & StateProps

const Floorplan: React.FC<Props> = props => {
  const ref = useRef<HTMLDivElement>(null)
  const {
    className,
    classes,
    selectedDrawing,
    selectedProject,
    updateFloorplanAndTableCanvas,
  } = props

  const [drawingLoaded, setDrawingLoaded] = useState<boolean>(false)
  const [image, setImage] = useState<HTMLImageElement | undefined>(undefined)
  const [imageWidth, setImageWidth] = useState<number>(0)
  const [imageHeight, setImageHeight] = useState<number>(0)
  const [initialScale, setInitialScale] = useState<number>(1)
  const [initialTranslate, setInitialTranslate] = useState<number[]>([0, 0])
  const [updateFloorplanCanvasDimensions, setUpdateFloorplanCanvasDimensions] = useState<number>(0)

  useEffect(() => {
    updateDrawing()
  }, [selectedDrawing])

  useEffect(() => {
    setUpdateFloorplanCanvasDimensions(updateFloorplanAndTableCanvas)
  }, [updateFloorplanAndTableCanvas])

  const updateDrawing = () => {
    if (selectedDrawing) {
      loadDrawingImage(getDrawingUrl(selectedDrawing))
    }
  }

  const loadDrawingImage = (src: string) => {
    setDrawingLoaded(false)
    const image = new Image()
    image.onload = () => {
      const { width, height } = image
      setImage(image)
      setInitialScale(computeScale(width, height))
      setInitialTranslate(computeTranslate())
      setImageWidth(width)
      setImageHeight(height)
      setDrawingLoaded(true)
    }
    image.crossOrigin = 'Anonymous'
    image.src = src
  }

  const getDrawingUrl = (drawing: string) => {
    return API.getDrawingUrl(selectedProject, drawing)
  }

  const computeScale = (width: number, height: number) => {
    if (ref.current) {
      const element = ref.current
      const xScale = element.clientWidth / width
      const yScale = element.clientHeight / height
      return Math.min(xScale, yScale)
    }
    return 1
  }

  const computeTranslate = () => {
    if (ref.current) {
      const element = ref.current
      return [element.clientWidth / 2, element.clientHeight / 2]
    }
    return [0, 0]
  }

  const drawingDisplay = () => {
    if (!selectedDrawing) {
      return <div />
    }

    if (!drawingLoaded) {
      return <CenteredSpinner />
    }

    return (
      <Zoomable
        className={classes.floorplanWrapper}
        initialScale={initialScale}
        initialTranslate={initialTranslate}
      >
        <FloorplanImage
          image={image}
          width={imageWidth}
          height={imageHeight}
          updateFloorplanCanvasDimensions={updateFloorplanCanvasDimensions}
        />
      </Zoomable>
    )
  }

  return (
    <div className={classNames(className, classes.root)} ref={ref}>
      {drawingDisplay()}
    </div>
  )
}

const mapStateToProps = (state: ReduxStore.State) => ({
  updateFloorplanAndTableCanvas: getUpdateFloorplanAndTableCanvas(state),
})

export default withSelectedValues(
  // @ts-ignore
  withStyles(styles)(
    connect(
      mapStateToProps,
      null
    )(Floorplan)
  )
)
