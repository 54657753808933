import CONST from 'const'
import { cloneDeep, max, min, round } from 'lodash'
import randomID from 'utils/randomId'
import { dimensionsToPoints, getBenchmarkPoint, pointsToDimensions, scalePoints } from 'utils/shape'

const initState: ReduxStore.Materials.State = {
  isFetching: false,
  list: {},
  selectFrom: CONST.SELECT_FROM_HOT,
  selection: [],
  descriptions: [],
  descriptionIds: [],
  descriptionTypes: [],
  listIds: [],
  listIdValueMap: {},
  listWithoutOverlays: [],
  recordIdsToDelete: [],
}

export default function MaterialsReducer(
  state: ReduxStore.Materials.State = initState,
  action: ReduxStore.Materials.Action
): ReduxStore.Materials.State {
  switch (action.type) {
    case 'REQUEST_MATERIALS':
      return {
        ...state,
        isFetching: true,
      }

    case 'RECEIVE_MATERIALS': {
      return {
        ...state,
        isFetching: false,
        list: action.payload.materials,
        descriptions: action.payload.descriptions,
        descriptionIds: action.payload.descriptionIds,
        descriptionTypes: action.payload.descriptionTypes,
        listIds: action.payload.listIds,
        listIdValueMap: action.payload.listIdValueMap,
      }
    }

    case 'RECEIVE_MATERIALS_WITHOUT_OVERLAYS': {
      return {
        ...state,
        listWithoutOverlays: Object.values(action.payload),
      }
    }

    case 'UPDATE_LIST_WITHOUT_OVERLAYS': {
      const materialIdArray = action.payload
      const listWithoutOverlays = state.listWithoutOverlays.filter(m => {
        const { material_id } = m
        return !materialIdArray.includes(material_id)
      })
      return {
        ...state,
        listWithoutOverlays,
      }
    }

    case 'ADD_MATERIAL': {
      return {
        ...state,
        list: {
          ...state.list,
          [action.payload.overlay_id]: action.payload,
        },
      }
    }

    case 'ADD_MATERIALS': {
      const extraList: { [index: string]: ReduxStore.Materials.Data.IMaterial } = {}
      action.payload.forEach((overlay: ReduxStore.Materials.Data.IMaterial) => {
        extraList[overlay.overlay_id] = overlay
      })
      return {
        ...state,
        list: {
          ...state.list,
          ...extraList,
        },
      }
    }

    case 'SET_SELECTION':
      return {
        ...state,
        selection: action.payload.selection,
        selectFrom: action.payload.selectFrom,
      }

    case 'RESET_SELECTION':
      return {
        ...state,
        selection: [],
      }

    case 'ADD_SELECTION':
      return {
        ...state,
        selection: [...state.selection, action.payload.selection],
        selectFrom: action.payload.selectFrom,
      }

    case 'DELETE_SELECTION': {
      const list = cloneDeep(state.list)
      // @ts-ignore
      action.payload.forEach(overlay_id => {
        if (overlay_id) {
          list[overlay_id].isDeleted = true
        }
      })
      return {
        ...state,
        list,
        selection: [],
      }
    }

    case 'DELETE_SELECTED_RECORDS_BY_IDS': {
      return {
        ...state,
        recordIdsToDelete: [...state.recordIdsToDelete, ...action.payload],
      }
    }

    case 'RESET_SELECTED_RECORDS_BY_IDS': {
      return {
        ...state,
        recordIdsToDelete: [],
      }
    }

    case 'DELETE_MATCH_SHAPE': {
      const list = cloneDeep(state.list)
      delete list[action.payload]
      return {
        ...state,
        list,
      }
    }

    case 'REMOVE_SELECTION': {
      const selection = state.selection.filter(overlay_id => overlay_id !== action.payload)
      return {
        ...state,
        selection,
      }
    }

    case 'SET_SELECT_FROM':
      return {
        ...state,
        selectFrom: action.payload,
      }

    case 'UPDATE_MATERIAL_IDS': {
      const idMap = cloneDeep(action.payload)
      const list = cloneDeep(state.list)
      Object.values(list).forEach(material => {
        const { material_id } = material
        const newId = idMap[material_id]
        if (newId) {
          material.material_id = newId
        }
      })
      return {
        ...state,
        list,
      }
    }

    case 'UPDATE_OVERLAY_IDS': {
      const newOverlayIds = cloneDeep(action.payload)
      const list = cloneDeep(state.list)
      Object.keys(newOverlayIds).forEach(oldId => {
        const material = cloneDeep(list[oldId])
        const newId = newOverlayIds[oldId]
        material.overlay_id = newId
        list[newId] = material
        delete list[oldId]
      })
      return {
        ...state,
        list,
      }
    }

    case 'UPDATE_CIRCLE_POS': {
      const { pos, pointIndex, material } = action.payload
      const { overlay_id } = material

      let shape_data = JSON.parse(material.shape_data)
      shape_data.points[pointIndex * 2] = pos[0]
      shape_data.points[pointIndex * 2 + 1] = pos[1]
      shape_data = JSON.stringify(shape_data)

      // update material list
      const list = cloneDeep(state.list)
      const selection = cloneDeep(state.selection)
      material.shape_data = shape_data
      list[overlay_id] = material
      list[overlay_id].dirtyOverlay = true

      return {
        ...state,
        selection,
        list,
      }
    }

    case 'UPDATE_MATCH_MATERIAL': {
      const list = cloneDeep(state.list)
      const certainty = action.payload
      Object.values(list).forEach(material => {
        const { weight, shape } = material
        if (
          weight != null &&
          weight >= certainty &&
          (shape === 'matchRectangle' || shape === 'matchPolygon')
        ) {
          material.isDeleted = false
        } else if (
          weight != null &&
          weight < certainty &&
          (shape === 'matchRectangle' || shape === 'matchPolygon')
        ) {
          material.isDeleted = true
        }
      })
      return {
        ...state,
        list,
      }
    }

    case 'DELETE_MATCH_MATERIALS': {
      const list = cloneDeep(state.list)
      Object.keys(list).forEach(overlayId => {
        if (
          list[overlayId].shape === 'matchRectangle' ||
          list[overlayId].shape === 'matchPolygon'
        ) {
          delete list[overlayId]
        }
      })
      return { ...state, list }
    }

    case 'CONFIRM_MATCH_MATERIALS': {
      const list = cloneDeep(state.list)
      Object.keys(list).forEach(overlayId => {
        const mat = list[overlayId]
        if (mat.isDeleted) {
          return
        }

        if (mat.shape === 'matchRectangle') {
          mat.shape = 'rectangle'
        }

        if (mat.shape === 'matchPolygon') {
          mat.shape = 'polygon'
        }
      })
      return { ...state, list }
    }

    case 'UPDATE_MATERIAL_POS': {
      const {
        absoluteX,
        absoluteY,
        offsetX,
        offsetY,
        material,
        rectRefs,
        polygonRefs,
      } = action.payload
      const list = cloneDeep(state.list)
      const { overlay_id, shape } = material

      // update rect siblings position
      // @ts-ignore
      rectRefs.forEach(ref => {
        // @ts-ignore
        const overlay_id = ref.getAttr('shapeId')
        // @ts-ignore
        const x = round(ref.getAttr('x'), 2)
        // @ts-ignore
        const y = round(ref.getAttr('y'), 2)
        // @ts-ignore
        const width = round(ref.getAttr('width'), 2)
        // @ts-ignore
        const height = round(ref.getAttr('height'), 2)
        const points = [
          x,
          y,
          round(x + width, 2),
          y,
          round(x + width, 2),
          round(y + height, 2),
          x,
          round(y + height, 2),
        ]
        list[overlay_id].shape_data = JSON.stringify({ points })
        list[overlay_id].dirtyOverlay = true
      })

      if (shape === 'rectangle' || shape === 'matchRectangle') {
        const { shape_data } = material
        let points = JSON.parse(shape_data).points
        const width = round(points[4] - points[0], 2)
        const height = round(points[5] - points[1], 2)
        const x = round(absoluteX, 2)
        const y = round(absoluteY, 2)
        points = [
          x,
          y,
          round(x + width, 2),
          y,
          round(x + width, 2),
          round(y + height, 2),
          x,
          round(y + height, 2),
        ]
        list[overlay_id].shape_data = JSON.stringify({ points })
        list[overlay_id].dirtyOverlay = true
      }
      // @ts-ignore
      polygonRefs.forEach(ref => {
        // @ts-ignore
        const overlay_id = ref.getAttr('shapeId')
        const shape_data: ReduxStore.Materials.Data.IShapeData = JSON.parse(
          list[overlay_id].shape_data
        )
        const points = shape_data.points.map((point, index) => {
          if (index % 2 === 0) {
            return round(point + offsetX, 2)
          } else {
            return round(point + offsetY, 2)
          }
        })
        list[overlay_id].shape_data = JSON.stringify({ points })
        list[overlay_id].dirtyOverlay = true
      })

      if (shape === 'polygon' || shape === 'matchPolygon') {
        const { shape_data } = material
        const points: number[] = JSON.parse(shape_data).points
        const newPoints = points.map((point, index) => {
          if (index % 2 === 0) {
            return round(point + offsetX, 2)
          } else {
            return round(point + offsetY, 2)
          }
        })
        list[overlay_id].shape_data = JSON.stringify({ points: newPoints })
        list[overlay_id].dirtyOverlay = true
      }

      return {
        ...state,
        list,
      }
    }

    case 'UPDATE_SCALE': {
      const { scaleX, scaleY, direct } = action.payload
      let benchmarkPoint: Array<number | undefined> | null = null
      const list = cloneDeep(state.list)
      if (state.selection.length > 1) {
        const selectedOverlays: ReduxStore.Materials.Data.IMaterial[] = []
        state.selection.forEach(overlay_id => selectedOverlays.push(list[overlay_id]))
        benchmarkPoint = getBenchmarkPoint(selectedOverlays, direct)
      }
      state.selection.forEach(overlay_id => {
        const { shape, shape_data } = list[overlay_id]
        if (shape === 'rectangle' || shape === 'matchRectangle') {
          let points = JSON.parse(shape_data).points
          points = scalePoints(scaleX, scaleY, direct, points, benchmarkPoint)
          list[overlay_id].shape_data = JSON.stringify({ points })
          list[overlay_id].dirtyOverlay = true
        }
      })

      return {
        ...state,
        list,
      }
    }

    case 'UPDATE_TRANSFORM': {
      const list = cloneDeep(state.list)
      state.selection.forEach(overlay_id => {
        const { shape } = list[overlay_id]
        if (shape === 'rectangle') {
          const points = dimensionsToPoints(action.payload)
          list[overlay_id].shape_data = JSON.stringify({ points })
          list[overlay_id].dirtyOverlay = true
        }
      })

      return {
        ...state,
        list,
      }
    }

    case 'UPDATE_OVERLAY_SHAPE': {
      const { overlay_id, shape_data, shape } = action.payload
      const list = cloneDeep(state.list)
      list[overlay_id].shape = shape
      list[overlay_id].shape_data = shape_data
      return {
        ...state,
        list,
      }
    }

    case 'ALIGN_SHAPES': {
      const list = cloneDeep(state.list)
      const rectangles = state.selection.reduce(
        (acc: ReduxStore.Materials.Data.IMaterial[], overlay_id) => {
          const overlay = list[overlay_id]
          if (overlay.shape === 'rectangle' || overlay.shape === 'matchRectangle') {
            acc.push(cloneDeep(overlay))
          }
          return acc
        },
        []
      )

      const direct = action.payload

      switch (direct) {
        case 'left': {
          const minX = min(rectangles.map(rect => JSON.parse(rect.shape_data).points[0]))
          rectangles.forEach(rect => {
            let points = JSON.parse(rect.shape_data).points
            const dimensions = pointsToDimensions(points)
            dimensions.x = minX
            points = dimensionsToPoints(dimensions)
            list[rect.overlay_id].shape_data = JSON.stringify({ points })
            list[rect.overlay_id].dirtyOverlay = true
          })
          break
        }
        case 'top': {
          const minY = min(rectangles.map(rect => JSON.parse(rect.shape_data).points[1]))
          rectangles.forEach(rect => {
            let points = JSON.parse(rect.shape_data).points
            const dimensions = pointsToDimensions(points)
            dimensions.y = minY
            points = dimensionsToPoints(dimensions)
            list[rect.overlay_id].shape_data = JSON.stringify({ points })
            list[rect.overlay_id].dirtyOverlay = true
          })
          break
        }
        case 'right': {
          const rightSide = max(
            rectangles.map(rect => {
              const points = JSON.parse(rect.shape_data).points
              const dimensions = pointsToDimensions(points)
              return dimensions.x + dimensions.width
            })
          )
          rectangles.forEach(rect => {
            let points = JSON.parse(rect.shape_data).points
            const dimensions = pointsToDimensions(points)
            if (rightSide) {
              dimensions.x = rightSide - dimensions.width
            }
            points = dimensionsToPoints(dimensions)
            list[rect.overlay_id].shape_data = JSON.stringify({ points })
            list[rect.overlay_id].dirtyOverlay = true
          })
          break
        }
        case 'bottom': {
          const bottomSide = max(
            rectangles.map(rect => {
              const points = JSON.parse(rect.shape_data).points
              const dimensions = pointsToDimensions(points)
              return dimensions.y + dimensions.height
            })
          )
          rectangles.forEach(rect => {
            let points = JSON.parse(rect.shape_data).points
            const dimensions = pointsToDimensions(points)
            if (bottomSide) {
              dimensions.y = bottomSide - dimensions.height
            }
            points = dimensionsToPoints(dimensions)
            list[rect.overlay_id].shape_data = JSON.stringify({ points })
            list[rect.overlay_id].dirtyOverlay = true
          })
          break
        }

        case 'height': {
          const firstMaterialOverlayId = state.selection[0]
          const points = JSON.parse(list[firstMaterialOverlayId].shape_data).points
          const dimensions = pointsToDimensions(points)
          const targetHeight = dimensions.height
          rectangles.forEach(rect => {
            let points = JSON.parse(rect.shape_data).points
            const dimensions = pointsToDimensions(points)
            dimensions.height = targetHeight
            points = dimensionsToPoints(dimensions)
            list[rect.overlay_id].shape_data = JSON.stringify({ points })
            list[rect.overlay_id].dirtyOverlay = true
          })
          break
        }

        case 'width': {
          const firstMaterialOverlayId = state.selection[0]
          const points = JSON.parse(list[firstMaterialOverlayId].shape_data).points
          const dimensions = pointsToDimensions(points)
          const targetWidth = dimensions.width
          rectangles.forEach(rect => {
            let points = JSON.parse(rect.shape_data).points
            const dimensions = pointsToDimensions(points)
            dimensions.width = targetWidth
            points = dimensionsToPoints(dimensions)
            list[rect.overlay_id].shape_data = JSON.stringify({ points })
            list[rect.overlay_id].dirtyOverlay = true
          })
          break
        }
      }

      return {
        ...state,
        list,
      }
    }

    case 'MERGE_OVERLAYS': {
      const overlaysTobeUpdate = action.payload
      const list = cloneDeep(state.list)
      let matsWithNoOverlay = cloneDeep(state.listWithoutOverlays)

      Object.keys(overlaysTobeUpdate).forEach(overlay_id => {
        const { oldValue, newValue } = overlaysTobeUpdate[overlay_id]
        let siblingsWithSameNewID = Object.values(list).filter(
          overlay =>
            overlay.value.ID === newValue &&
            overlay.value.ID !== '' &&
            overlay.overlay_id.toString() !== overlay_id
        )
        // List Materials that are gaining an overlay.
        const matsGainingOverlay = matsWithNoOverlay.filter(
          mat => mat.value.ID === newValue && mat.value.ID !== ''
        )
        // Remove materials gaining an overlay from the list of materials gaining an overlay.
        matsWithNoOverlay = matsWithNoOverlay.filter(
          noOverlayMat =>
            !matsGainingOverlay.find(
              matGaining => matGaining.material_id === noOverlayMat.material_id
            )
        )
        // @ts-ignore
        siblingsWithSameNewID = siblingsWithSameNewID.concat(matsGainingOverlay)

        const siblingsWithSameOldID = Object.values(list).filter(
          overlay =>
            overlay.value.ID === oldValue &&
            overlay.value.ID !== '' &&
            overlay.overlay_id.toString() !== overlay_id
        )

        if (oldValue === '') {
          //  update overlay for current specific one
          if (siblingsWithSameNewID.length > 0) {
            const newValueFromSibling = cloneDeep(siblingsWithSameNewID[0].value)
            const newMaterialId = siblingsWithSameNewID[0].material_id
            list[overlay_id].value = newValueFromSibling
            list[overlay_id].material_id = newMaterialId
          } else {
            list[overlay_id].value.ID = newValue
          }
          list[overlay_id].isUpdateDes = true
          list[overlay_id].dirtyOverlay = true
        }
        if (oldValue !== '') {
          // merge to other existing siblings
          if (siblingsWithSameNewID.length > 0) {
            const newValueFromSibling = cloneDeep(siblingsWithSameNewID[0].value)
            const newMaterialId = siblingsWithSameNewID[0].material_id
            list[overlay_id].value = newValueFromSibling
            list[overlay_id].material_id = newMaterialId
            list[overlay_id].dirtyOverlay = true
            list[overlay_id].isUpdateDes = true
            siblingsWithSameOldID.forEach(overlay => {
              list[overlay.overlay_id].value = newValueFromSibling
              list[overlay.overlay_id].material_id = newMaterialId
              list[overlay.overlay_id].dirtyOverlay = true
              list[overlay.overlay_id].isUpdateDes = true
            })
            return
          }

          if (siblingsWithSameNewID.length === 0) {
            list[overlay_id].value.ID = newValue
            list[overlay_id].isUpdateDes = true
            siblingsWithSameOldID.forEach(overlay => {
              list[overlay.overlay_id].value.ID = newValue
              list[overlay.overlay_id].isUpdateDes = true
              if (newValue === '') {
                list[overlay.overlay_id].material_id = randomID()
              }
            })
          }
        }
      })

      return {
        ...state,
        list,
        listWithoutOverlays: matsWithNoOverlay,
      }
    }

    case 'UPDATE_MATERIAL_DES': {
      const list = cloneDeep(state.list)
      const { hotData, rowsTobeUpdated } = action.payload

      rowsTobeUpdated
        // @ts-ignore
        .filter(rowIndex => hotData[rowIndex].overlay_id.length > 0 && hotData[rowIndex].shape_data)
        // @ts-ignore
        .forEach(rowIndex => {
          const des = hotData[rowIndex]
          const { ID, overlay_id } = cloneDeep(des)
          const shape_data = JSON.parse(des.shape_data)
          delete des.overlay_id
          delete des.shape_data
          if (ID === '') {
            list[overlay_id[0]].value = des
            list[overlay_id[0]].isUpdateDes = true
          } else {
            Object.keys(list).forEach(overlay_id => {
              const overlay = list[overlay_id]
              if (overlay.value.ID === ID) {
                overlay.value = des
                overlay.isUpdateDes = true
              }
            })
          }
          Object.keys(shape_data).forEach(overlay_id => {
            const overlay = list[overlay_id]
            const { shape } = overlay
            const shapeData = shape_data[overlay_id]
            if (shape === 'rectangle' || shape === 'matchRectangle') {
              list[overlay_id].shape_data = JSON.stringify({
                points: dimensionsToPoints(shapeData.points),
              })
              list[overlay_id].dirtyOverlay = true
            } else {
              list[overlay_id].shape_data = JSON.stringify(shapeData)
              list[overlay_id].dirtyOverlay = true
            }
          })
        })
      return {
        ...state,
        list,
      }
    }

    case 'UPDATE_MATERIAL_MATCH': {
      const list = cloneDeep(state.list)
      const { overlayIdTobeUpdated, overlayShape } = action.payload
      Object.keys(list).forEach(overlay_id => {
        const overlay = list[overlay_id]
        if (overlay_id === overlayIdTobeUpdated) {
          if (overlayShape === 'rectangle') {
            overlay.shape = 'rectangle'
          }
          if (overlayShape === 'polygon') {
            overlay.shape = 'polygon'
          }
        }
      })
      return {
        ...state,
        list,
      }
    }

    case 'SET_MATERIAL_AS_OLD': {
      const list = Object.values(state.list).reduce(
        (acc: { [key in number | string]: ReduxStore.Materials.Data.IMaterial }, material) => {
          const materialCopy = cloneDeep(material)
          if (materialCopy.isUpdateDes) {
            delete materialCopy.isUpdateDes
          }
          if (materialCopy.dirtyOverlay) {
            delete materialCopy.dirtyOverlay
          }
          acc[materialCopy.overlay_id] = materialCopy
          return acc
        },
        {}
      )
      return {
        ...state,
        list,
      }
    }

    default:
      return state
  }
}
