export const selectTool = (toolName: string): ReduxStore.Tool.SelectTool => {
  return {
    type: 'SELECT_TOOL',
    payload: toolName,
  }
}

export const setSnappingEnabled = (enabled: boolean): ReduxStore.Tool.SetSnappingEnabled => {
  return {
    type: 'SET_SNAPPING_ENABLED',
    payload: enabled,
  }
}

export const setToolOptions = (
  toolName: string,
  options: ReduxStore.Tool.ToolOptions
): ReduxStore.Tool.SetToolOptions => {
  return {
    type: 'SET_TOOL_OPTIONS',
    toolName,
    options,
  }
}
