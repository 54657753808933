import { createStyles, WithStyles } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import tools from 'dataloader/tools/tools'
import Konva from 'konva'
import { get, throttle } from 'lodash'
import React from 'react'
import { Image, Layer, Stage } from 'react-konva'
import { connect } from 'react-redux'
import action from 'store/actions'
import {
  getSelectedMaterials,
  getSelectedMaterialType,
  getSnappingEnabled,
  getTool,
} from 'store/selectors'
import { Matrix } from 'transformation-matrix'
import { getScale, getTranslate, vectorToObject } from 'utils/mathUtils'
import withSelectedValues from 'utils/withSelectedValues'
import MaterialOverlay from './MaterialOverlay'
import ToolOverlay from './ToolOverlay'
import TransformerComponent from './Transformer'

const styles = createStyles({
  root: {
    display: 'flex',
    flex: 1,
    position: 'relative',
  },
})

interface StateProps {
  currentTool: string
  selectedMaterials: Array<number | string>
  selectedDrawing: string
  selectedMaterialType: string
  snappingEnabled: boolean
  updateFloorplanCanvasDimensions: number
}

interface DispatchProps {
  dispatchResetSelection: () => void
  dispatchUpdateScale: (scale: ReduxStore.Materials.Data.INewScale) => void
}

interface Ownprops {
  image: HTMLImageElement
  width: number
  height: number
  matrix: Matrix
}

type Props = Readonly<StateProps & DispatchProps & WithStyles<typeof styles> & Ownprops>

type ComponentState = Readonly<{ canvasDimensions: any[] }>

class FloorplanImage extends React.Component<Props, ComponentState> {
  ref = React.createRef<HTMLDivElement>()
  imageRef = React.createRef<Konva.Image>()

  handleWindowResize = throttle(this.updateCanvasDimensions.bind(this))

  readonly state: ComponentState = {
    canvasDimensions: [],
  }

  componentDidMount() {
    this.updateCanvasDimensions()
    window.addEventListener('resize', this.handleWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  componentDidUpdate(prevProps: any) {
    // Typical usage (don't forget to compare props):
    if (this.props.updateFloorplanCanvasDimensions !== prevProps.updateFloorplanCanvasDimensions) {
      this.updateCanvasDimensions()
      window.addEventListener('resize', this.handleWindowResize)
    }
  }

  getRefDimensions() {
    if (this.ref.current) {
      return [this.ref.current.clientWidth, this.ref.current.clientHeight]
    }
    return [0, 0]
  }

  getToolStyles() {
    const { currentTool } = this.props
    return get(tools, `${currentTool}.canvasStyles`, {})
  }

  handleStageMouseDown = (e: any) => {
    // clicked on stage - cler selection
    const { dispatchResetSelection } = this.props
    if (e.target === e.target.getStage()) {
      dispatchResetSelection()
    }
  }

  updateCanvasDimensions() {
    this.setState({ canvasDimensions: this.getRefDimensions() })
  }

  renderTransformer() {
    const {
      selectedMaterials,
      selectedDrawing,
      selectedMaterialType,
      dispatchUpdateScale,
    } = this.props
    if (selectedMaterials.length > 0) {
      return selectedMaterials.map(overlayId => (
        <TransformerComponent
          key={`transformer-${overlayId}`}
          selectedMaterials={selectedMaterials}
          selectedMaterialName={overlayId}
          dispatchUpdateScale={dispatchUpdateScale}
          snappingEnabled={this.props.snappingEnabled}
          selectedDrawing={selectedDrawing}
          selectedMaterialType={selectedMaterialType}
        />
      ))
    }
    return null
  }

  renderMaterialOverlay() {
    const { matrix } = this.props
    const scale: { x: number; y: number } = vectorToObject(getScale(matrix))
    // @ts-ignore
    return <MaterialOverlay scale={scale} />
  }

  renderStage(canvasDimensions: number[]) {
    const canvasWidth = canvasDimensions[0]
    const canvasHeight = canvasDimensions[1]
    const { image, height, width, matrix } = this.props
    const scale: { x: number; y: number } = vectorToObject(getScale(matrix))
    const position = vectorToObject(getTranslate(matrix))
    const style = this.getToolStyles()

    return (
      <Stage
        width={canvasWidth}
        height={canvasHeight}
        style={style}
        onMouseDown={this.handleStageMouseDown}
      >
        <Layer scale={scale} x={position.x} y={position.y} offset={{ x: width / 2, y: height / 2 }}>
          <Image image={image} listening={false} ref={this.imageRef} />
          {this.renderMaterialOverlay()}
          <ToolOverlay
            width={width}
            height={height}
            scale={scale}
            image={image}
            imageRef={this.imageRef.current}
          />
          {this.renderTransformer()}
        </Layer>
      </Stage>
    )
  }

  render() {
    const { classes } = this.props
    const { canvasDimensions } = this.state
    return (
      <div className={classes.root} ref={this.ref}>
        {canvasDimensions && this.renderStage(canvasDimensions)}
      </div>
    )
  }
}

const mapStateToProps = (
  state: ReduxStore.State,
  {
    selectedDrawing,
    selectedMaterialType,
  }: { selectedDrawing: string; selectedMaterialType: string }
) => ({
  currentTool: getTool(state),
  selectedMaterials: getSelectedMaterials(state),
  snappingEnabled: getSnappingEnabled(state),
  materialType: getSelectedMaterialType(state, parseInt(selectedMaterialType, 10)),
  selectedDrawing,
})

const mapDispatchToProps = {
  dispatchResetSelection: action.resetSelection,
  dispatchUpdateScale: action.updateScale,
}

export default withSelectedValues(
  // @ts-ignore
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(FloorplanImage)
  )
)
