import { createStyles, WithStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button/Button'
import Icon from '@material-ui/core/Icon/Icon'
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip'
import MagicTool from 'dataloader/tools/MagicTool'
import MLExtract from 'dataloader/tools/MLExtract'
import tools from 'dataloader/tools/tools'
import React from 'react'
import { connect } from 'react-redux'
import action from 'store/actions'
import {
  getDescriptions,
  getDrawings,
  getSelectedMaterials,
  getSelectedMaterialsShape,
  getTool,
} from 'store/selectors'
import withSelectedValues from 'utils/withSelectedValues'

const styles = createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '-8px',
    marginLeft: '-8px',
    marginRight: '-8px',
  },
  toolWrapper: {
    width: '50%',
    boxSizing: 'border-box',
    padding: '8px',
  },
  tool: {
    width: '100%',
  },
})

interface StateProps {
  currentTool: string
  selectedMaterials: Array<number | string>
  materials: ReduxStore.Materials.Data.IMaterial[]
  drawings: ReduxStore.Drawings.Data.Drawing[]
  selectedProject: number | null
  selectedMaterialType: number | null
  descriptions: string[]
  selectedDrawing: string | null
}

interface DispatchProps {
  dispatchSelectTool: (tool: string) => void
  dispatchAddMaterials: (materia: ReduxStore.Materials.Data.IMaterial) => void
}

type Props = WithStyles<typeof styles> & StateProps & DispatchProps

const ToolList: React.FunctionComponent<Props> = props => {
  const selectTool = (toolName: string) => {
    const { dispatchSelectTool } = props
    dispatchSelectTool(toolName)
  }

  const renderTool = (id: string, icon: React.ReactElement, tooltip: string) => {
    const { classes, currentTool } = props
    return (
      <div key={id || '_'} className={classes.toolWrapper}>
        <Tooltip title={tooltip} enterDelay={800}>
          <Button
            variant="outlined"
            color={currentTool === id ? 'primary' : undefined}
            className={classes.tool}
            onClick={() => selectTool(id)}
          >
            {icon}
          </Button>
        </Tooltip>
      </div>
    )
  }

  const { descriptions, selectedDrawing } = props
  const value = descriptions.reduce((acc: { [key: string]: string }, name) => {
    if (name === 'Drawing Name' && selectedDrawing) {
      acc[name] = selectedDrawing
    } else {
      acc[name] = ''
    }
    return acc
  }, {})

  const renderTools = () => {
    const { classes, selectedMaterials, materials } = props
    return [
      renderTool('', <Icon>touch_app</Icon>, ''),
      ...Object.keys(tools).map(toolName => {
        // @ts-ignore
        const tool = tools[toolName]
        return renderTool(toolName, tool.icon, tool.tooltip || '')
      }),
      <MagicTool key={'magic-wand'} />,
      <MLExtract key={'ml-extract'} />,
    ]
  }

  const { classes } = props

  return <div className={classes.root}>{renderTools()}</div>
}

const mapStateToProps = (state: ReduxStore.State) => ({
  currentTool: getTool(state),
  selectedMaterials: getSelectedMaterials(state),
  materials: getSelectedMaterialsShape(state),
  drawings: getDrawings(state),
  descriptions: getDescriptions(state),
})

const mapDispatchToProps = {
  dispatchSelectTool: action.selectTool,
  dispatchAddMaterials: action.addMaterial,
}

export default withSelectedValues(
  // @ts-ignore
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ToolList))
)
