import CONST from 'const'
import {
  drawGuides,
  getAnchorPoints,
  getChangeToGuide,
  getGuides,
  getLineGuideStops,
} from 'dataloader/utils/guideLines'
import Konva from 'konva'
import { cloneDeep, isEmpty, round } from 'lodash'
import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import action from 'store/actions'
import {
  getMaterialsOnCurrentDrawing,
  getSelectedMaterials,
  getSelectFrom,
  getSnappingEnabled,
} from 'store/selectors'
import withSelectedValues from 'utils/withSelectedValues'
import MatchPolygon from './Shapes/MatchPolygon'
import MatchRectangle from './Shapes/MatchRectangle'
import Polygon from './Shapes/Polygon'
import Rectangle from './Shapes/Rectangle'

interface StateProps {
  materials: ReduxStore.Materials.Data.IMaterial[]
  selectFrom: number
  selectedMaterials: Array<number | string>
  snappingEnabled: boolean
}

interface DispatchProps {
  dispatchAddSelection: (selection: number | string, selectFrom: number) => void
  dispatchDeleteSelectedShapes: (selection: Array<number | string>) => void
  dispatchSetSelectFrom: (selectFrom: number) => void
  dispatchSetSelection: (selection: Array<number | string>, selectFrom: number) => void
  dispatchUpdatePos: (newPos: ReduxStore.Materials.Data.INewMaterialPos) => void
  dispatchUpdateCirclePos: (newPos: ReduxStore.Materials.Data.INewCirclePos) => void
}

interface OwnProps {
  scale: { x: number; y: number }
}

type Props = StateProps & DispatchProps & OwnProps

// @ts-ignore
const MaterialOverlay: React.FC<Props> = props => {
  const {
    materials,
    scale,
    selectFrom,
    selectedMaterials,
    dispatchSetSelection,
    dispatchAddSelection,
    dispatchSetSelectFrom,
    dispatchDeleteSelectedShapes,
    dispatchUpdatePos,
    dispatchUpdateCirclePos,
  } = props

  const lastestProps = useRef<Props>()
  let absoluteX: number = 0
  let absoluteY: number = 0
  let offsetX: number = 0
  let offsetY: number = 0
  let siblingRefs: Array<Konva.Group | Konva.Rect> = []
  let rectSiblingRefs: Konva.Rect[] = []
  let polygonSiblingRefs: Konva.Group[] = []
  let rectOriginX: { [key in number | string]: number } = {}
  let rectOriginY: { [key in number | string]: number } = {}

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  useEffect(() => {
    lastestProps.current = props
  })

  const handleKeyDown = (event: KeyboardEvent) => {
    if (lastestProps.current) {
      const { selectedMaterials, selectFrom } = lastestProps.current
      if (
        event.keyCode === CONST.KEY_DELETE &&
        !isEmpty(selectedMaterials) &&
        selectFrom === CONST.SELECT_FROM_VIEWER
      ) {
        dispatchDeleteSelectedShapes(selectedMaterials)
      }
    }
  }

  const singleClickOnShape = (
    evt: Konva.KonvaEventObject<MouseEvent>,
    material: ReduxStore.Materials.Data.IMaterial
  ) => {
    evt.evt.stopPropagation()
    const { overlay_id } = material
    if (evt.evt.shiftKey) {
      if (!selectedMaterials.some(id => id === overlay_id)) {
        dispatchAddSelection(overlay_id, CONST.SELECT_FROM_VIEWER)
        return
      }
      if (selectFrom === CONST.SELECT_FROM_HOT) {
        dispatchSetSelectFrom(CONST.SELECT_FROM_VIEWER)
      }
    } else {
      if (!(selectedMaterials.length === 1 && selectedMaterials[0] === overlay_id)) {
        dispatchSetSelection([overlay_id], CONST.SELECT_FROM_VIEWER)
        return
      }
      if (selectFrom === CONST.SELECT_FROM_HOT) {
        dispatchSetSelectFrom(CONST.SELECT_FROM_VIEWER)
      }
    }
  }

  const onShapeDragStart = (overlayId: number | string) => {
    rectOriginX = {}
    rectOriginY = {}
    siblingRefs = window.shapeRefs.filter(
      ref =>
        ref &&
        ref.getAttr('shapeId') !== overlayId &&
        selectedMaterials.some(
          overlayId =>
            overlayId === ref.getAttr('shapeId') && overlayId === ref.getAttr('materialBelongsTo')
        )
    )
    rectSiblingRefs = siblingRefs.filter(ref => ref.getAttr('shape') === 'rect') as Konva.Rect[]
    polygonSiblingRefs = siblingRefs.filter(
      ref => ref.getAttr('shape') === 'polygon'
    ) as Konva.Group[]
    rectSiblingRefs.forEach(ref => {
      rectOriginX[ref.getAttr('shapeId')] = ref.getAttr('x')
      rectOriginY[ref.getAttr('shapeId')] = ref.getAttr('y')
    })
  }

  const getPolygonSnappingEdges = (
    node: Konva.Shape | Konva.Stage
  ): { vertical: object[]; horizontal: object[] } => {
    // @ts-ignore
    const points = node.getAttrs().points
    if (!points) {
      return { vertical: [], horizontal: [] }
    }
    const vertical: object[] = []
    const horizontal: object[] = []
    points.forEach((point: number, i: number) => {
      if (i % 2 === 0) {
        vertical.push({
          guide: round(point + node.x(), 2),
          offset: point,
          snap: 'end',
        })
      } else {
        horizontal.push({
          guide: round(point + node.y(), 2),
          offset: point,
          snap: 'end',
        })
      }
    })
    return {
      vertical,
      horizontal,
    }
  }

  const getObjectSnappingEdges = (node: Konva.Shape | Konva.Stage) => {
    const box = node.getAttrs()
    if (box.x && box.y && box.width && box.height) {
      return {
        vertical: [
          {
            guide: Math.round(box.x),
            offset: Math.round(node.x() - box.x),
            snap: 'start',
          },
          {
            guide: Math.round(box.x + box.width),
            offset: Math.round(node.x() - box.x - box.width),
            snap: 'end',
          },
        ],
        horizontal: [
          {
            guide: Math.round(box.y),
            offset: Math.round(node.y() - box.y),
            snap: 'start',
          },
          {
            guide: Math.round(box.y + box.height),
            offset: Math.round(node.y() - box.y - box.height),
            snap: 'end',
          },
        ],
      }
    }
    return null
  }

  const onShapeDragMove = (
    evt: Konva.KonvaEventObject<MouseEvent>,
    material: ReduxStore.Materials.Data.IMaterial
  ) => {
    const { shape } = material
    if (shape === 'rectangle' || shape === 'matchRectangle') {
      // click on rect to drag
      const { shape_data } = material
      const points = JSON.parse(shape_data).points
      const dimensions = {
        x: points[0],
        y: points[1],
        width: points[4] - points[0],
        height: points[5] - points[1],
      }
      // find possible snapping lines
      // @ts-ignore
      evt.target
        .getStage()
        .find('.guid-line')
        // @ts-ignore
        .destroy()
      if (props.snappingEnabled) {
        const lineGuideStops = getLineGuideStops(evt.target)
        const itemBounds = getObjectSnappingEdges(evt.target)
        const guides = getGuides(lineGuideStops, itemBounds)
        drawGuides(guides, evt.target)
        const { presetX, presetY } = getAnchorPoints(guides)
        if (presetX) {
          evt.target.x(presetX)
        }
        if (presetY) {
          evt.target.y(presetY)
        }
      }

      rectSiblingRefs.forEach(ref => {
        ref.x(rectOriginX[ref.getAttr('shapeId')] + evt.target.x() - dimensions.x)
        ref.y(rectOriginY[ref.getAttr('shapeId')] + evt.target.y() - dimensions.y)
      })
      polygonSiblingRefs.forEach(ref => {
        ref.x(evt.target.x() - dimensions.x)
        ref.y(evt.target.y() - dimensions.y)
      })

      return
    }

    if (shape === 'polygon' || shape === 'matchPolygon') {
      if (props.snappingEnabled) {
        const lineGuideStops = getLineGuideStops(evt.target)
        const itemBounds = getPolygonSnappingEdges(evt.target)
        const guides = getGuides(lineGuideStops, itemBounds)
        // find possible snapping lines
        // @ts-ignore
        evt.target
          .getStage()
          .find('.guid-line')
          // @ts-ignore
          .destroy()
        drawGuides(guides, evt.target)

        const { presetX, presetY } = getChangeToGuide(guides)
        if (presetX) {
          evt.target.x(round(evt.target.x() + presetX, 2))
        }
        if (presetY) {
          evt.target.y(round(evt.target.y() + presetY, 2))
        }
      }
      // click on polygon to drag
      const offsetX = round(evt.target.x(), 2)
      const offsetY = round(evt.target.y(), 2)
      polygonSiblingRefs.forEach(ref => {
        ref.x(offsetX)
        ref.y(offsetY)
      })
      rectSiblingRefs.forEach(ref => {
        ref.x(rectOriginX[ref.getAttr('shapeId')] + offsetX)
        ref.y(rectOriginY[ref.getAttr('shapeId')] + offsetY)
      })
    }
  }

  const onShapeDragEnd = (
    evt: Konva.KonvaEventObject<MouseEvent>,
    material: ReduxStore.Materials.Data.IMaterial
  ) => {
    // @ts-ignore
    evt.target
      .getStage()
      .find('.guid-line')
      // @ts-ignore
      .destroy()
    // @ts-ignore
    evt.target.getStage().batchDraw()

    const { shape, shape_data } = material
    const points = JSON.parse(shape_data).points
    const dimensions = {
      x: points[0],
      y: points[1],
      width: points[4] - points[0],
      height: points[5] - points[1],
    }

    if (shape === 'rectangle' || shape === 'matchRectangle') {
      absoluteX = evt.target.x()
      absoluteY = evt.target.y()
      offsetX = absoluteX - dimensions.x
      offsetY = absoluteY - dimensions.y
    } else if (shape === 'polygon' || shape === 'matchPolygon') {
      offsetX = evt.target.x()
      offsetY = evt.target.y()
    }

    dispatchUpdatePos({
      absoluteX,
      absoluteY,
      offsetX,
      offsetY,
      material,
      rectRefs: rectSiblingRefs,
      polygonRefs: polygonSiblingRefs,
    })
  }

  const mapDataToShape = () => {
    window.shapeRefs = []
    return materials.map(material => {
      const { overlay_id, shape } = cloneDeep(material)
      switch (shape) {
        case 'rectangle':
          return (
            <Rectangle
              key={overlay_id}
              material={{ ...material }}
              onMouseDown={singleClickOnShape}
              onDragStart={onShapeDragStart}
              onDragMove={onShapeDragMove}
              onDragEnd={onShapeDragEnd}
              {...props}
            />
          )
        case 'matchRectangle':
          return (
            <MatchRectangle
              key={overlay_id}
              material={{ ...material }}
              onMouseDown={singleClickOnShape}
              onDragStart={onShapeDragStart}
              onDragMove={onShapeDragMove}
              onDragEnd={onShapeDragEnd}
              {...props}
            />
          )
        case 'polygon':
          return (
            <Polygon
              key={overlay_id}
              material={cloneDeep(material)}
              scale={scale}
              onMouseDown={singleClickOnShape}
              onDragStart={onShapeDragStart}
              onDragMove={onShapeDragMove}
              onDragEnd={onShapeDragEnd}
              dispatchUpdateCirclePos={dispatchUpdateCirclePos}
              selectedMaterials={selectedMaterials}
              snappingEnabled={props.snappingEnabled}
            />
          )
        case 'matchPolygon':
          return (
            <MatchPolygon
              key={overlay_id}
              material={cloneDeep(material)}
              scale={scale}
              onMouseDown={singleClickOnShape}
              onDragStart={onShapeDragStart}
              onDragMove={onShapeDragMove}
              onDragEnd={onShapeDragEnd}
              dispatchUpdateCirclePos={dispatchUpdateCirclePos}
              selectedMaterials={selectedMaterials}
            />
          )
        default:
          return null
      }
    })
  }

  return mapDataToShape()
}

const mapStateToProps = (
  state: ReduxStore.State,
  { selectedDrawing }: { selectedDrawing: string }
) => ({
  materials: getMaterialsOnCurrentDrawing(state, selectedDrawing),
  selectFrom: getSelectFrom(state),
  selectedMaterials: getSelectedMaterials(state),
  snappingEnabled: getSnappingEnabled(state),
})

const mapDispatchToProps = {
  dispatchAddSelection: action.addSelection,
  dispatchDeleteSelectedShapes: action.deleteSelection,
  dispatchSetSelectFrom: action.setSelectFrom,
  dispatchSetSelection: action.setSelection,
  dispatchUpdatePos: action.updateMaterialPos,
  dispatchUpdateCirclePos: action.updateCirclePos,
}

export default withSelectedValues(
  // @ts-ignore
  connect(
    mapStateToProps,
    mapDispatchToProps
    // @ts-ignore
  )(MaterialOverlay)
)
